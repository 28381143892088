import PieChartIcon from '@mui/icons-material/PieChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box, Skeleton } from '@mui/material';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceMLevel } from 'constants/photoIntelligence';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAllLocation } from 'services/clients/apiClient.services';
import { useAppSelector } from 'store/hooks';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import FindingsChart from './FindingsChart';
import SeverityLevelChart from './SeverityLevelChart';
import StatusChart from './StatusChart';

const ChartArea = () => {
  const { levelId, isLastLevelId } = useAppSelector(mapViewSelector);
  const [isChartMode, setIsChartMode] = useState<boolean>(true);

  const { data: photoIntelligenceData, isLoading } = useQuery<IPhotoIntelligenceMLevel[]>(
    [QUERY_KEY.ALL_LOCATION_PHOTO_INTELLIGENCE, levelId],
    () => getPhotoIntelligenceAllLocation(levelId!),
    {
      enabled: !!levelId && !isLastLevelId,
    }
  );

  const flattedData = photoIntelligenceData?.map((item) => item.data)?.flat();

  const renderSkeleton = (
    <Box>
      {[1, 2, 3].map((item) => (
        <Skeleton
          key={item}
          variant="rectangular"
          sx={{
            display: 'flex',
            height: '200px',
            width: '100%',
            marginY: '12px',
          }}
        />
      ))}
    </Box>
  );

  const renderChart = (
    <Box
      sx={{
        pt: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
      }}>
      <StatusChart data={flattedData} isChartMode={isChartMode} />
      <SeverityLevelChart data={flattedData} isChartMode={isChartMode} />
      <FindingsChart data={flattedData} isChartMode={isChartMode} />
    </Box>
  );

  return isLoading ? (
    renderSkeleton
  ) : (
    <Box position="relative">
      <Box
        sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, cursor: 'pointer' }}
        onClick={() => setIsChartMode(!isChartMode)}>
        {isChartMode ? <PieChartIcon /> : <TableChartIcon />}
      </Box>
      {renderChart}
    </Box>
  );
};

export default ChartArea;
