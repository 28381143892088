import { Box, Typography } from '@mui/material';
import { countOccurrencesArray } from 'common/utils/count';
import { IPhotoIntelligenceData, SeverityColor, ToggleEnumType } from 'constants/photoIntelligence';
import ReactECharts from 'echarts-for-react'; // or var ReactECharts = require('echarts-for-react');
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { photoIntelligenceSelector } from 'store/slices/map-view/photoIntelligenceAnalytics';
import VisualizationTableCommon from './components/VisualizationTableCommon';

interface SeverityLevelChartProps {
  data: IPhotoIntelligenceData[] | undefined;
  isChartMode: boolean;
}

const SeverityLevelChart: FC<SeverityLevelChartProps> = ({ data, isChartMode }) => {
  const { status, severityLevel, frequency } = useAppSelector(photoIntelligenceSelector);
  const { t } = useTranslation();
  const filteredListData = data
    ?.filter((item) =>
      status.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.includes(item.status)
    )
    ?.filter((item) =>
      frequency.listData
        .filter((each) => each.value)
        ?.map((each) => each.key)
        ?.includes(item.frequency)
    )
    ?.map((item) => ({
      severityLevels: item.severityLevels.filter((ele) =>
        severityLevel.listData
          .filter((each) => each.value)
          ?.map((each) => each.key)
          ?.includes(ele)
      ),
    }));

  const reducedListData = filteredListData?.reduce(
    (acc, cur) => {
      acc.severityLevels = [...acc.severityLevels, ...cur.severityLevels];
      return acc;
    },
    { severityLevels: [] }
  );

  const occurrenceData = countOccurrencesArray(reducedListData?.severityLevels || [], ToggleEnumType.SEVERITY_LEVELS);

  const finalListData = occurrenceData?.map((item) => ({
    value: item.value,
    itemStyle: { color: SeverityColor[item.key] },
  }));

  const option = {
    xAxis: {
      type: 'category',
      data: [t('trans.high'), t('trans.medium'), t('trans.low'), t('trans.ground_features')],
      axisLabel: {
        fontSize: 10,
        interval: 0,
        formatter: function (value: string) {
          return value.split(' ').join('\n');
        },
      },
    },
    yAxis: {
      type: 'value',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    series: [
      {
        name: 'Values',
        type: 'bar',
        data: finalListData || [],
        barWidth: '100%',
        barCategoryGap: 0,
      },
    ],
  };

  const renderChart = (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <ReactECharts option={option} lazyUpdate={true} theme={'theme_name'} style={{ height: '280px', width: '60%' }} />
    </Box>
  );

  const renderTable = <VisualizationTableCommon data={occurrenceData} fieldName="Severity Level" />;

  return (
    <Box>
      <Typography>{t('trans.severity_level')}</Typography>
      {isChartMode ? renderChart : renderTable}
    </Box>
  );
};

export default SeverityLevelChart;
