export enum PhotoIntelligenceStatusEnum {
  OPEN = 'open',
  CLOSED = 'close',
}

export enum SeverityLevelEnum {
  HIGH = 'High',
  MEDIUM = 'Medium',
  LOW = 'Low',
  GROUND_FEATURES = 'Ground Features',
}

export const SeverityColor = {
  [SeverityLevelEnum.HIGH]: '#d5463c',
  [SeverityLevelEnum.MEDIUM]: '#5686E1',
  [SeverityLevelEnum.LOW]: '#ffc14d',
  [SeverityLevelEnum.GROUND_FEATURES]: '#41e349',
};

export enum FrequencyEnum {
  WEEKLY = 'week',
  MONTHLY = 'monthly',
  THREE_MONTHLY = '3 monthly',
  SIX_MONTHLY = '6 monthly',
}

export interface IToggle {
  key: PhotoIntelligenceStatusEnum | SeverityLevelEnum | FrequencyEnum;
  label: string;
  value: boolean;
}

export const LockColor = {
  [PhotoIntelligenceStatusEnum.OPEN]: '#FF0506',
  [PhotoIntelligenceStatusEnum.CLOSED]: '#00FF0F',
};

export interface VideoMetadata {
  hour: number;
  minute: number;
  second: number;
}

export interface SurroundingImage {
  position: string;
  name: string;
}

export interface IParent {
  _id: string;
  parent?: IParent;
  level: number;
}

export interface IPhotoIntelligenceData {
  _id: string;
  analysisId: string;
  kpName: string;
  names: string[];
  severityLevels: SeverityLevelEnum[];
  frequency: FrequencyEnum;
  status: PhotoIntelligenceStatusEnum;
  lat: number;
  lon: number;
  alt: number;
  videoMetadata: VideoMetadata;
  surroundingImages: SurroundingImage[];
}

export interface IPhotoIntelligenceMLevel {
  level: IParent;
  data: IPhotoIntelligenceData[];
}

export interface CommentIntelligence {
  date: string;
  user: string;
  comment: string;
}

export interface IKpData {
  key: SeverityLevelEnum;
  value: number;
}

export enum ToggleEnumType {
  SEVERITY_LEVELS = 'SEVERITY_LEVELS',
  STATUS = 'STATUS',
  FINDINGS = 'FINDINGS',
}

export interface IPhotoIntelligenceComment {
  _id: string;
  photoIntelligenceId: string;
  user: {
    _id: string;
    name: string;
  };
  content: string;
  createdAt: Date;
  updatedAt: Date;
}

export const s3BaseUrl = 'https://aerodyne-agrimor-dev.s3.ap-southeast-1.amazonaws.com/';
