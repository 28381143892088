import { Box, Stack, Typography } from '@mui/material';
import { IKpData, SeverityColor } from 'constants/photoIntelligence';
import { FC } from 'react';
import CircleBox from './CircleBox';

interface KpDetailProps {
  title: string;
  kpData: IKpData[];
  width?: number;
}

const KpDetail: FC<KpDetailProps> = ({ title, kpData, width }) => {
  const convertedKpData = kpData?.map((item) => ({
    key: item.key,
    label: item.value,
    color: SeverityColor[item.key],
  }));

  return (
    <Box
      sx={{
        background: 'rgba(0, 0, 0, 0.5)',
        width: width || '120px',
        padding: '4px 8px',
        borderRadius: '10px',
      }}>
      <Stack>
        <Typography>{title}</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '104px',
            mt: '4px',
          }}>
          {convertedKpData?.map((item) => (
            <CircleBox key={item.key} color={item.color} label={item.label} isCollapseSpace />
          ))}
        </Box>
      </Stack>
    </Box>
  );
};

export default KpDetail;
