import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Collapse, FormControlLabel, Typography } from '@mui/material';
import { SwitchCustom } from 'components/Common/SwitchCustom';
import { useBoxLayout, useLayerSelectCardStyle } from 'components/MapView/MapViewStyle';
import { t } from 'i18next';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import ChartArea from './chart-area';
import ToggleArea from './ToggleArea';

const PhotoIntelligenceCollapse = () => {
  const [isShowPhotoIntelligence, setIsShowPhotoIntelligence] = useState<boolean>(false);
  const [isShowVisualization, setIsShowVisualization] = useState<boolean>(false);

  const classes = useLayerSelectCardStyle();
  const classes2 = useBoxLayout();

  return (
    <Box className={classes2.boxLayout} style={{ padding: '20px 20px', borderRadius: '20px', marginBottom: '20px' }}>
      <Button
        endIcon={
          isShowPhotoIntelligence ? (
            <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
          ) : (
            <ChevronRightIcon style={{ fontWeight: 'bold' }} />
          )
        }
        onClick={() => setIsShowPhotoIntelligence(!isShowPhotoIntelligence)}
        classes={{ root: classes.buttonTextStyle }}
        style={{ width: '100%' }}>
        <Typography
          className={classes.buttonTextStyle}
          style={{ marginRight: 'auto', fontWeight: 'bold', fontSize: '14px !important' }}>
          {t('trans.photo_intelligence')}
        </Typography>
      </Button>
      <Collapse in={isShowPhotoIntelligence} timeout="auto" unmountOnExit sx={{ px: 1.5 }}>
        <ToggleArea />

        <Box sx={{ mt: '24px' }}>
          <FormControlLabel
            sx={{ ml: 0.5 }}
            control={
              <SwitchCustom
                checked={isShowVisualization}
                onChange={() => setIsShowVisualization(!isShowVisualization)}
              />
            }
            label={t('trans.visualization')}
          />
        </Box>
        {isShowVisualization && (
          <Scrollbars style={{ height: '300px' }}>
            <ChartArea />
          </Scrollbars>
        )}
      </Collapse>
    </Box>
  );
};

export default PhotoIntelligenceCollapse;
