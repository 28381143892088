import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { FORMAT_DATE, QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceData } from 'constants/photoIntelligence';
import moment from 'moment';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { getPhotoIntelligenceAssociatedKps, getPhotoIntelligenceKpDetail } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeAssociatedKpInfo,
  changeDetailBarList,
  changeSelectedKpInfo,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import { changeParentAnalyticsId, mapViewSelector } from 'store/slices/mapViewSlice';

interface IPhotoIntelligenceDetailData extends Omit<IPhotoIntelligenceData, 'analysisId'> {
  analysisId: {
    _id: string;
    date: Date;
    parentId: string;
    name: string;
  };
}

interface AssociatedKpsProps {
  data: IPhotoIntelligenceData;
}

const AssociatedKps: FC<AssociatedKpsProps> = ({ data }) => {
  const [kpList, setKpList] = useState<IPhotoIntelligenceData[]>([]);
  const { t } = useTranslation();
  const { _id } = data || {};
  const { levelId, dateAnalyticSelected } = useAppSelector(mapViewSelector);
  const { barDetailList } = useAppSelector(photoIntelligenceSelector);
  const dispatch = useAppDispatch();

  const { isLoading, isRefetching } = useQuery<IPhotoIntelligenceData[]>(
    [QUERY_KEY.PHOTO_INTELLIGENCE_ASSOCIATED_LIST, _id, levelId],
    () =>
      getPhotoIntelligenceAssociatedKps({
        kpId: _id,
        levelId: levelId!,
      }),
    {
      onSuccess(data) {
        setKpList(data);
      },
      enabled: !!_id && !!levelId,
    }
  );

  const getPhotoIntelligenceKpDetailMutation = useMutation(getPhotoIntelligenceKpDetail, {
    onSuccess(data: IPhotoIntelligenceDetailData) {
      const {
        _id: targetKpId,
        lon,
        lat,
        analysisId: { date, parentId, _id: analysisId },
      } = data;
      const formattedDate = moment.utc(date).format(FORMAT_DATE);
      if (dateAnalyticSelected !== formattedDate) {
        dispatch(changeAssociatedKpInfo({ id: targetKpId, date: formattedDate, isDone: false }));
        dispatch(changeParentAnalyticsId({ parentId: parentId, analyticId: analysisId }));
      }

      const newBarDetailList = barDetailList.map((item) =>
        item.id === targetKpId ? { ...item, isOpenCollapse: true } : { ...item, isOpenCollapse: false }
      );
      dispatch(changeDetailBarList(newBarDetailList));
      dispatch(changeSelectedKpInfo({ _id: targetKpId, lon, lat, isDone: false }));

      onScrollToTargetKp(targetKpId);
    },
  });

  const onScrollToTargetKp = (targetId: string) => {
    const element = document.getElementById(`photo_bar_${targetId}`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  };

  const renderSkeleton = [1, 2].map((_, i) => <Skeleton key={i} variant="text" sx={{ fontSize: '1rem' }} />);

  const renderKpList = kpList.map((item) => (
    <Typography
      key={item._id}
      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => getPhotoIntelligenceKpDetailMutation.mutate({ kpId: item._id })}>
      {item.kpName}
    </Typography>
  ));

  return (
    <Box sx={{ mt: '8px' }}>
      <Typography>{t('trans.associated_kps')}</Typography>
      <Stack>{isLoading || isRefetching ? renderSkeleton : renderKpList}</Stack>
    </Box>
  );
};

export default AssociatedKps;
