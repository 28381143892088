import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceData } from 'constants/photoIntelligence';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getPhotoIntelligenceAnalysis } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  changeAssociatedKpInfo,
  changeDetailBarList,
  photoIntelligenceSelector,
} from 'store/slices/map-view/photoIntelligenceAnalytics';
import { mapViewSelector } from 'store/slices/mapViewSlice';
import CommentViewModal from './modals/CommentViewModal';
import ImageViewModal from './modals/ImageViewModal';
import VideoViewModal from './modals/VideoViewModal';
import PhotoIntelligenceBar from './photo-intelligence-bar';

const PhotoIntelligenceAnalytics = () => {
  const { imageViewModal, videoViewModal, commentViewModal, associatedKp } = useAppSelector(photoIntelligenceSelector);
  const { analyticId, isLastLevelId, dateAnalyticSelected, ratersSelected } = useAppSelector(mapViewSelector);
  const [listData, setListData] = useState<IPhotoIntelligenceData[]>([]);
  const dispatch = useAppDispatch();

  useQuery<IPhotoIntelligenceData[]>(
    [QUERY_KEY.PHOTO_INTELLIGENCE_ANALYSIS, analyticId, dateAnalyticSelected, ratersSelected],
    () => getPhotoIntelligenceAnalysis(analyticId!),
    {
      enabled: !!analyticId && isLastLevelId,
      onSuccess(data) {
        setListData(data);
        const detailBarList = data?.map((item) => ({ id: item._id, isOpenCollapse: associatedKp.id === item._id }));
        if (detailBarList?.length) {
          dispatch(changeDetailBarList(detailBarList));
          dispatch(changeAssociatedKpInfo({ id: '', date: '', isDone: true }));
        }
      },
    }
  );

  // reset data when analyticId = null
  useEffect(() => {
    if (!analyticId) {
      setListData([]);
    }
  }, [analyticId]);

  const imageViewModalData = listData?.find((item) => item._id === imageViewModal.id);
  const videoViewModalData = listData?.find((item) => item._id === videoViewModal.id);
  const commentViewModalData = listData?.find((item) => item._id === commentViewModal.id);

  return (
    <>
      {listData?.map((item) => (
        <PhotoIntelligenceBar key={item._id} data={item} />
      ))}
      {imageViewModal.isShow && <ImageViewModal data={imageViewModalData} />}
      {videoViewModal.isShow && <VideoViewModal data={videoViewModalData} />}
      {commentViewModal.isShow && <CommentViewModal data={commentViewModalData} />}
    </>
  );
};

export default PhotoIntelligenceAnalytics;
