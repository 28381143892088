import SendIcon from '@mui/icons-material/Send';
import { Box, Button, TextField } from '@mui/material';
import { IMetadata } from 'common/defines/clients';
import CommonModal from 'components/Common/CommonModal';
import { QUERY_KEY } from 'constants/constants';
import { IPhotoIntelligenceComment, IPhotoIntelligenceData } from 'constants/photoIntelligence';
import { t } from 'i18next';
import moment from 'moment';
import { ChangeEvent, FC, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { createPhotoIntelligenceComment, getPhotoIntelligenceComment } from 'services/clients/apiClient.services';
import { useAppDispatch } from 'store/hooks';
import { changeIsShowCommentViewModal } from 'store/slices/map-view/photoIntelligenceAnalytics';

interface CommentViewModalProps {
  data: IPhotoIntelligenceData | undefined;
}

const CommentViewModal: FC<CommentViewModalProps> = ({ data }) => {
  const [listComments, setListComments] = useState<IPhotoIntelligenceComment[]>([]);
  const [newComment, setNewComment] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const { _id } = data || {};
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  useQuery(
    [QUERY_KEY.PHOTO_INTELLIGENCE_COMMENT_LIST, _id, page],
    () => getPhotoIntelligenceComment({ page, perPage: 50, photoIntelligenceId: _id! }),
    {
      enabled: !!_id,
      onSuccess(res) {
        const commentData = res.data?.data as IPhotoIntelligenceComment[];
        const metaData = res.data?.metadata as IMetadata;

        setListComments((prev) => [...prev, ...commentData]);
        if (metaData?.nextPage) {
          setPage((prev) => prev + 1);
        }
      },
    }
  );

  const createCommentMutation = useMutation(createPhotoIntelligenceComment, {
    onSuccess: () => {
      setNewComment('');
      setListComments([]);
      toast.success('Create new message successful');
      queryClient.invalidateQueries([QUERY_KEY.PHOTO_INTELLIGENCE_COMMENT_LIST]);
    },
    onError: (error) => {
      console.error('Error creating comment:', error);
    },
  });
  const handleSendComment = () => {
    _id &&
      createCommentMutation.mutate({
        photoIntelligenceId: _id,
        content: newComment,
      });
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    setNewComment(value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendComment();
    }
  };

  return (
    <CommonModal
      style={{ height: '700px', width: '600px', borderRadius: '8px', padding: '20px' }}
      isShow={true}
      handleClose={() => dispatch(changeIsShowCommentViewModal({ id: '', isShow: false }))}>
      <Scrollbars style={{ height: '500px' }} autoHide>
        <Box
          style={{
            borderRadius: '8px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}>
          <Box style={{ maxHeight: '200px', marginTop: '20px' }}>
            {listComments?.map((item, index) => (
              <Box
                key={index}
                style={{ marginBottom: '15px', border: '1px solid #333', borderRadius: '8px', padding: '10px' }}>
                <Box style={{ fontSize: '12px', color: '#ccc' }}>{moment(item.updatedAt).format('DD-MM-YYYY')}</Box>
                <Box style={{ fontSize: '14px', fontWeight: 'bold' }}>{item.user?.name}</Box>
                <Box style={{ fontSize: '14px', whiteSpace: 'pre-wrap' }}>{item.content}</Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Scrollbars>

      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #23BE6A',
          borderRadius: '8px',
          position: 'fixed',
          bottom: '10px',
          left: '5px',
          right: '5px',
        }}>
        <TextField
          type="text"
          placeholder={t('trans.type_new_messages')}
          style={{ flex: 1 }}
          value={newComment}
          onChange={(e) => handleChangeInput(e)}
          onKeyDown={handleKeyDown}
          minRows={1}
          maxRows={5}
          fullWidth
          multiline
          InputProps={{
            sx: {
              '& fieldset': {
                border: 'none',
              },
            },
          }}
        />
        <Button style={{ cursor: 'pointer' }} onClick={handleSendComment}>
          <SendIcon />
        </Button>
      </Box>
    </CommonModal>
  );
};

export default CommentViewModal;
