import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { Box, IconButton, styled, TextField, Typography, useTheme } from '@mui/material';
import { RenderTree } from 'common/defines/clients';
import { defaultIconFavoriteSetting } from 'common/dummy/dummyClients';
import { QUERY_KEY } from 'constants/constants';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getListLevelIdFavorite,
  postLevelRecent,
  setFavoriteAnalytic,
} from 'services/analytics/apiAnalyticsConfig.services';
import { getClientSettingsById } from 'services/clients/apiClient.services';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { changeIsPolygonMode } from 'store/slices/map-view/standCountAnalytics';
import { changeLevelId, ICropTreeLevelObj, mapViewSelector } from 'store/slices/mapViewSlice';
import { changeAnalyticName } from 'store/slices/rightBarSlice';
import { clearVigorAnalytics } from '../../../store/slices/map-view/vigorAnalytics';
import { useMapViewStyle } from '../MapViewStyle';

interface IStyledTreeItem {
  bgColor?: string;
  color?: string;
  labelInfo?: string;
  labelText: string;
  level: number;
  isLastLevel?: boolean;
  isFavorite?: any;
  refetch: any;
  isFavoriteLocation?: boolean;
  expanded?: Array<string>;
  setSearchClient: (parentNode: string, searchString?: string) => void;
  maxLevel: number;
  levels: RenderTree[] | ICropTreeLevelObj[];
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    width: 'inherit',
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = (props: TreeItemProps & IStyledTreeItem) => {
  const {
    bgColor,
    color,
    labelInfo,
    labelText,
    isLastLevel,
    isFavorite,
    refetch,
    expanded,
    setSearchClient,
    maxLevel,
    ...other
  } = props;
  const dispatch = useAppDispatch();
  const classes = useMapViewStyle();
  const [favorite, setFavorite] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { clientId } = useParams();
  const { levelId } = useAppSelector(mapViewSelector);
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const { data: clientData } = useQuery(
    [QUERY_KEY.CLIENT_SETTINGS_BY_ID, clientId],
    () => getClientSettingsById(clientId || ''),
    {
      enabled: !!clientId,
    }
  );

  const clientSettingFavorite = useMemo(() => {
    if (!clientData || clientData.favouriteSetting === null) return defaultIconFavoriteSetting;
    return clientData.favouriteSetting;
  }, [clientData]);

  const iconFavoriteSetting = useMemo(() => {
    if (!clientSettingFavorite && isFavorite) {
      return (
        <StarRoundedIcon
          sx={{
            color: theme.palette.color.yellow300,
          }}
        />
      );
    } else if (!clientSettingFavorite && !isFavorite) {
      return (
        <StarBorderRoundedIcon
          sx={{
            color: theme.palette.color.grey7,
            transition: 'color 0.3s ease-in-out',
            '&:hover': {
              color: theme.palette.color.yellow300,
            },
          }}
        />
      );
    } else if (clientSettingFavorite && isFavorite) {
      if (clientSettingFavorite.icon === 'StarRoundedIcon') {
        return (
          <StarRoundedIcon
            sx={{
              color: clientSettingFavorite.color,
            }}
          />
        );
      } else if (clientSettingFavorite.icon === 'FavoriteIcon') {
        return (
          <FavoriteIcon
            sx={{
              color: clientSettingFavorite.color,
            }}
          />
        );
      }
    } else if (clientSettingFavorite && !isFavorite) {
      if (clientSettingFavorite.icon === 'StarRoundedIcon') {
        return (
          <StarBorderRoundedIcon
            sx={{
              color: theme.palette.color.grey7,
              transition: 'color 0.3s ease-in-out',
              '&:hover': {
                color: clientSettingFavorite.color,
              },
            }}
          />
        );
      } else if (clientSettingFavorite.icon === 'FavoriteIcon') {
        return (
          <FavoriteBorderIcon
            sx={{
              color: theme.palette.color.grey7,
              transition: 'color 0.3s ease-in-out',
              '&:hover': {
                color: clientSettingFavorite.color,
              },
            }}
          />
        );
      }
    }
  }, [clientSettingFavorite, isFavorite]);

  const onSelected = useCallback(() => {
    if (levelId !== other.nodeId) {
      dispatch(changeLevelId({ levelId: other.nodeId, isLevelLasted: !!isLastLevel, levels: other.levels }));
      dispatch(changeAnalyticName(null));
      //reset vigor analytic
      dispatch(clearVigorAnalytics());
      dispatch(changeIsPolygonMode(false));
    }
  }, [dispatch, other, isLastLevel, levelId]);

  const setNewFavorite = useMutation((data: any) => setFavoriteAnalytic(data), {
    onSuccess: (res) => {
      setFavorite(!favorite);
      refetch();
      res.data.deletedCount === 1
        ? toast.error('Your favorite analytic has been removed', { toastId: 1 })
        : toast.success('Your favorite analytic has been saved', { toastId: 1 });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const searchClient = (currentNode: string, value: string) => {
    setSearchClient(currentNode, value);
    setSearchText(value);
  };

  const checkSecondLastItemWhichContainChildren = useMemo(() => {
    return other.level === maxLevel - 1 && expanded?.includes(other.nodeId) && !isEmpty(other.children);
  }, [other, maxLevel, expanded]);

  useEffect(() => {
    !expanded?.includes(other.nodeId) && !searchText && setOpen(false);
  }, [expanded]);

  return (
    <StyledTreeItemRoot
      {...other}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5, pr: 0 }}>
          <Box sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
            <Typography onClick={() => onSelected()} variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
              {labelText}
            </Typography>
            {checkSecondLastItemWhichContainChildren && open && (
              <TextField
                type="text"
                placeholder="Search..."
                sx={{
                  marginTop: '5px',
                  width: '90%',
                  height: '28.2px',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '.MuiOutlinedInput-root': {
                    height: '28.2px',
                    padding: '0px',
                  },
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: '5px',
                }}
                value={searchText}
                onChange={(e) => {
                  searchClient(other.nodeId, e.target.value);
                }}
                onClick={(e) => e.stopPropagation()}
                InputProps={{
                  endAdornment: <SearchIcon />,
                }}
              />
            )}
          </Box>
          {checkSecondLastItemWhichContainChildren && !open && (
            <SearchIcon
              onClick={(e) => {
                setOpen(true);
                e.stopPropagation();
              }}
            />
          )}
          <Typography variant="caption" color="inherit" className={classes.labelInfo}>
            {labelInfo}
          </Typography>
          {isLastLevel && (
            <IconButton
              size="small"
              onClick={() =>
                setNewFavorite.mutate({
                  levelId: other.nodeId,
                })
              }
              sx={{
                display: 'flex',
                width: '24px',
                height: '24px',
              }}>
              {iconFavoriteSetting}
            </IconButton>
          )}
        </Box>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
    />
  );
};

export const LevelTree = (props: any) => {
  const { levels, callBackSearching, maxLevel } = props;
  const { levelId } = useAppSelector(mapViewSelector);
  const [expanded, setExpanded] = useState<any>([]);
  const { clientId } = useParams();
  const { data: getAllListIdFavorite, refetch } = useQuery([QUERY_KEY.GET_FAVORITE_ANALYTIC, clientId], () =>
    getListLevelIdFavorite(clientId || '')
  );
  const theme = useTheme();
  const listIdFavorite = useMemo(() => {
    if (!getAllListIdFavorite) return [];
    return getAllListIdFavorite.data;
  }, [getAllListIdFavorite]);

  useEffect(() => {
    setExpanded((item: any) => {
      return [...new Set([levelId, ...item])];
    });
  }, [levelId, setExpanded]);

  const setRecentLocation = useMutation((levelId: string) => postLevelRecent(levelId));

  const selfHandle = (currentNode: string, value?: string) => {
    callBackSearching(currentNode, value);
  };

  const renderTree = (nodes: RenderTree) => {
    return (
      <StyledTreeItem
        maxLevel={maxLevel}
        expanded={expanded}
        level={nodes.level}
        key={nodes._id}
        nodeId={nodes._id}
        isLastLevel={nodes.isLastLevel}
        labelText={nodes.name}
        bgColor={'#6FE4A5'}
        color={theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000'}
        isFavorite={listIdFavorite.includes(nodes._id)}
        refetch={refetch}
        onClick={() => {
          setRecentLocation.mutate(nodes._id);
        }}
        setSearchClient={selfHandle}
        labelInfo={(nodes.children?.length || '') + ''}
        levels={levels}>
        {Array.isArray(nodes.children) ? nodes.children.map((node: any) => renderTree(node)) : null}
      </StyledTreeItem>
    );
  };

  return (
    <TreeView
      aria-label="structure"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={levelId ? [levelId] : []}
      onNodeToggle={(_, nodeIds: any) => setExpanded(nodeIds)}
      expanded={expanded}
      defaultEndIcon={<div style={{ width: 24 }} />}
      sx={{ flexGrow: 1, mt: 2 }}>
      {levels && levels.map(renderTree)}
    </TreeView>
  );
};
