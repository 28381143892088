import { Box } from '@mui/material';
import { countOccurrencesArray } from 'common/utils/count';
import CircleBox from 'components/KpDetail/CircleBox';
import { IPhotoIntelligenceData, SeverityColor, ToggleEnumType } from 'constants/photoIntelligence';
import { FC } from 'react';

interface SeverityLevelProps {
  data: IPhotoIntelligenceData;
}

const SeverityLevel: FC<SeverityLevelProps> = ({ data }) => {
  const kpData = countOccurrencesArray(data.severityLevels, ToggleEnumType.SEVERITY_LEVELS);

  const convertedKpData = kpData?.map((item) => ({
    key: item.key,
    label: item.value,
    color: SeverityColor[item.key],
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '84px',
      }}>
      {convertedKpData?.map((item) => (
        <CircleBox key={item.key} color={item.color} label={item.label} isCollapseSpace />
      ))}
    </Box>
  );
};

export default SeverityLevel;
